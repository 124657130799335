<template>
	<div class="mastContent">
		<div class="voyage-button">
			<button class="voyage-btn el-icon-plus" @click="addReport">Add</button>
		</div>
		<el-table
			:data="tableData"
			style="width: 100%;"
			size="small"
			border
			header-row-class-name="table-header"
			height="80vh"
			@row-click="selectRow"
		>
			<el-table-column prop="seg" label="Seg" width="60" align="center">
				<template slot-scope="scope">
					<el-popover
						v-if="scope.row.notice && scope.row.notice.length"
						placement="top-start"
						width="200"
						trigger="hover"
						popper-class="monitor-yt-popover"
					>
						<div>
							<p v-for="data in scope.row.notice" :key="data">{{ data }}</p>
						</div>
						<div slot="reference" class="imgNumber">
							<img src="@/assets/right-img/notice-red.png" width="16px" />
							{{ scope.row.seg ? scope.row.seg : '' }}
						</div>
					</el-popover>
					<div class="imgNumber" v-else>
						<!-- <img src="@/assets/right-img/notice.png" width="16px" /> -->
						{{ scope.row.seg ? scope.row.seg : '' }}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="dateUtc" label="Date（UTC）" width="180" align="center">
				<template slot-scope="scope">
					<div class="imgNumber">{{ scope.row.typeSon[0] + '   ' }}{{ scope.row.dateUtc }}</div>
				</template></el-table-column
			>
			<el-table-column prop="timeSlr" label="Time SLR" align="center">
				<template slot-scope="scope">
					<el-tooltip v-if="scope.row.timeSlrConsult" effect="dark" :content="scope.row.timeSlrConsult" placement="top">
						<span style="color: red">{{ scope.row.timeSlr }}</span>
					</el-tooltip>
					<span v-else>{{ scope.row.timeSlr }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="distanceSlr" label="Dist SLR" align="center">
				<template slot-scope="scope">
					<el-tooltip
						v-if="scope.row.distanceSlrConsult"
						effect="dark"
						:content="scope.row.distanceSlrConsult"
						placement="top"
					>
						<span style="color: red">{{ scope.row.distanceSlr }}</span>
					</el-tooltip>
					<span v-else>{{ scope.row.distanceSlr }}</span>
				</template></el-table-column
			>
			<el-table-column prop="avgSpeedSlr" label="Avg Speed SLR" align="center"></el-table-column>
			<el-table-column prop="totalDgoConsSlr" label="Total FO Cons SLR" align="center"></el-table-column>
			<el-table-column prop="totalFoConsSlr" label="Total DGO Cons SLR" align="center"></el-table-column>
		</el-table>
	</div>
</template>

<script>
import { getMasterReport } from '@/api/basic';
import mapobj from '@/utils/mapw.js';
import { pointToLine, getPointListByTime, getDistanceSlrByTime } from '@/utils/myTruf.js';
let icon = L.icon({
	iconUrl: require('@/assets/img/redPoint.png'), // 图标文件路径
	iconSize: [40, 40], // 图标尺寸
	iconAnchor: [0, 0], // 图标偏移位置
});
export default {
	props: {
		simulationData: {
			type: Object,
			default: () => {},
		},
		tableData: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		simulationData: {
			handler: function (val) {
				if (Object.keys(val)) {
					let table = val.cpData.filter((item) => item.ld === 'Laden'); // report1需要修改的内容为Ballast
					// this.getReport();
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			//tableData: this.tableData,
			// [
			// 	{
			// 		Seg: 1,
			// 		data: 'N 23-12-06/0900Z',
			// 		WPT_Name: '-',
			// 	},
			// ],
		};
	},
	mounted() {
		// this.$bus.$off('editReportTable');
		// this.$bus.$on('editReportTable', (data) => {
		// 	this.getReport();
		// });
		// this.getReport();
	},
	methods: {
		addReport() {
			this.$bus.$emit('copyReportTable');
		},
		// 选中行
		selectRow(data) {
			this.$bus.$emit('reportInfoShow', true);
		},
		// 获取日报
		// getReport() {
		// 	this.tableData = [];
		// 	// 移除日报图层
		// 	mapobj.reportLayer.clearLayers();
		// 	let data = this.simulationData.cpData.filter((item) => item.ld === 'Laden'); // TabpaneSimulation2需要修改为Laden
		// 	getMasterReport({
		// 		id: data[0].parentId,
		// 		type: 'laden',
		// 	}).then((res) => {
		// 		if (res && res.length) {
		// 			let portList = [];
		// 			res.forEach((element) => {
		// 				if (element.masterReportCosp) {
		// 					portList.push({
		// 						time: element.masterReportCosp.cospDate,
		// 						departurePort: element.masterReportCosp.departurePort,
		// 					});
		// 				}
		// 			});
		// 			if (portList && portList.length) {
		// 				this.$bus.$emit('updatePortList', portList);
		// 			}
		// 		}
		// 		let line;
		// 		if (mapobj.vesselTrackData?.vesselTrackGeometrys) {
		// 			mapobj.vesselTrackData.vesselTrackGeometrys.features.forEach((e) => {
		// 				if (e.geometry.type.includes('Line')) {
		// 					line = e;
		// 				}
		// 			});
		// 		}
		// 		if (line) {
		// 			res.forEach((element, index) => {
		// 				element.masterReportInfo.seg = [];
		// 				element.masterReportInfo.speed = 1;
		// 				// 参考经纬度
		// 				element.masterReportInfo.latConsult = null;
		// 				element.masterReportInfo.lonConsult = null;
		// 				// 参考distanceSlr
		// 				element.masterReportInfo.distanceSlrConsult = null;
		// 				// 参考timeSlr
		// 				element.masterReportInfo.timeSlrConsult = null;
		// 				let lat = mapobj.ChangeToDu(element.masterReportInfo.lat.replace('′', ''));
		// 				let lon = mapobj.ChangeToDu(element.masterReportInfo.lon.replace('′', ''));
		// 				if (lat && lon) {
		// 					// 将时间字符串转换为时间戳（毫秒）
		// 					const inputTime = new Date(element.masterReportInfo.dateUtc).getTime();
		// 					let consult = getPointListByTime(inputTime, null, line);
		// 					let distance = pointToLine([lon, lat], line);
		// 					if (distance > 3) {
		// 						// 取报文的时间字段，然后提取该时间前后相邻两个时间的AIS点位，按照报文时间在这两个AIS点时间中的位置比例，作为报文分析点位在两AIS点连线上对应比例的位置。
		// 						if (consult) {
		// 							element.masterReportInfo.latConsult = consult[1];
		// 							element.masterReportInfo.lonConsult = consult[0];
		// 							element.masterReportInfo.seg.push('LAT');
		// 							element.masterReportInfo.seg.push('LON');
		// 							// element.masterReportInfo.seg.push(
		// 							// 	'报文中的经纬度信息，与AIS中分析得到的经纬度信息距离，相差超过3nm告警；',
		// 							// );
		// 							// console.log('🚀 ~ res.forEach ~ 报文中的经纬度信息，与AIS中分析得到的经纬度信息距离，相差超过3nm告警；:')
		// 						}
		// 					}
		// 				}
		// 				if (index > 0) {
		// 					// 参考timeSlr
		// 					if (element.masterReportInfo.timeSlr !== '-') {
		// 						const date1 = new Date(element.masterReportInfo.dateUtc);
		// 						const date2 = new Date(res[index - 1].masterReportInfo.dateUtc);
		// 						const diffInMilliseconds = Math.abs(date2 - date1);
		// 						let timeSlr = parseFloat((diffInMilliseconds / (1000 * 60 * 60)).toFixed(3));
		// 						if (timeSlr * 1 != element.masterReportInfo.timeSlr) {
		// 							element.masterReportInfo.timeSlrConsult = timeSlr;
		// 							element.masterReportInfo.seg.push('Time SLR');
		// 						}
		// 					}
		// 					// 参考distanceSlr
		// 					// 两个午报点之间的所有AIS点之间的线段距离相加，与dist SLR对比，超过3nm，dist SRL标红，铃铛变红，鼠标移到铃铛上时会显示异常信息，鼠标移到dist slr框时显示系统分析值。
		// 					// 在计算AIS点之间的线段距离时，导入的午报经纬度也作为AIS点参与计算
		// 					function returnCoordinates(data) {
		// 						lat = mapobj.ChangeToDu(data.lat.replace('′', ''));
		// 						lon = mapobj.ChangeToDu(data.lon.replace('′', ''));
		// 						lon = lon < 0 ? lon + 360 : lon;
		// 						return [lon, lat];
		// 					}
		// 					let aisDistance = getDistanceSlrByTime({
		// 						startTime: new Date(res[index - 1].masterReportInfo.dateUtc).getTime(),
		// 						endTime: new Date(element.masterReportInfo.dateUtc).getTime(),
		// 						starLatLon: returnCoordinates(res[index - 1].masterReportInfo),
		// 						endLatLon: returnCoordinates(element.masterReportInfo),
		// 					});
		// 					if (aisDistance) {
		// 						if (Math.abs(element.masterReportInfo.distanceSlr - aisDistance) > 3) {
		// 							element.masterReportInfo.seg.push('Dist SLR');
		// 							element.masterReportInfo.distanceSlrConsult = aisDistance.toFixed(3);
		// 						}
		// 					}
		// 					// 当前报文的total cons，应该等于这份报文的BROB-前份报文的BROB的绝对值
		// 					let typeList = ['Hfo', 'Lsfo', 'Mdo', 'Mgo'];
		// 					let needSumList = ['totalConsSlr', 'brob'];
		// 					let filed = true;
		// 					// 判断是否存在字段
		// 					typeList.forEach((e) => {
		// 						needSumList.forEach((val) => {
		// 							if (
		// 								(!element.masterReportInfo[val + e] && element.masterReportInfo[val + e] !== 0) ||
		// 								element.masterReportInfo[val + e] === '-'
		// 							) {
		// 								filed = false;
		// 							}
		// 							if (
		// 								(!res[index - 1].masterReportInfo['brob' + e] &&
		// 									res[index - 1].masterReportInfo['brob' + e] !== 0) ||
		// 								res[index - 1].masterReportInfo['brob' + e] === '-'
		// 							) {
		// 								filed = false;
		// 							}
		// 						});
		// 					});
		// 					if (filed) {
		// 						// 参考totalConsSlr
		// 						typeList.forEach((e) => {
		// 							if (
		// 								element.masterReportInfo['totalConsSlr' + e] * 10000 !=
		// 								Math.abs(
		// 									res[index - 1].masterReportInfo['brob' + e] * 10000 -
		// 										element.masterReportInfo['brob' + e] * 10000,
		// 								)
		// 							) {
		// 								element.masterReportInfo['totalConsSlr' + e + 'Consult'] = Math.abs(
		// 									res[index - 1].masterReportInfo['brob' + e] * 10000 -
		// 										element.masterReportInfo['brob' + e] * 10000,
		// 								) / 10000;
		// 								element.masterReportInfo.seg.push('Total Cons ' + e.toUpperCase());
		// 							}
		// 						});
		// 					}
		// 				}
		// 				if (
		// 					Math.abs(
		// 						element.masterReportInfo.avgSpeedSlr -
		// 							element.masterReportInfo.distanceSlr / element.masterReportInfo.timeSlr,
		// 					) > 0.2
		// 				) {
		// 					element.masterReportInfo.seg.push('Avg Speed SLR');
		// 					// element.masterReportInfo.seg.push('Avg Speed SLR与Dist To Go/Time SLR两者之间，相差超过0.2告警）');
		// 				}
		// 				this.tableData.push(element.masterReportInfo);
		// 			});
		// 		} else {
		// 			res.forEach((element) => {
		// 				this.tableData.push(element.masterReportInfo);
		// 			});
		// 		}
		// 		console.log(res);
		// 		mapobj.addPointByTable(this.tableData);
		// 		this.$bus.$emit('reportTable', res);
		// 	});
		// },
	},
};
</script>

<style lang="scss" scoped>
$tableHeaderColor: #ebeef4;
// .mastContent{
// 	height: calc(80vh + 32px);
// 	width: 100%;
// }
.imgNumber {
	display: flex;
	align-items: center;
	justify-content: center;
}

::v-deep .el-table {
	width: 100%;
	border-radius: 6px;

	.el-table__header {
		height: 68px;
		background: $tableHeaderColor;

		.table-header {
			color: $defaultFontColor;
			font-weight: bold;
		}
	}

	.edit-row {
		background: rgb(203, 234, 234) !important;
	}

	.el-table__row {
		.el-form-item {
			margin-bottom: 0;
			color: $defaultFontColor;

			.lng-lat-input {
				width: 240px !important;
			}
		}

		.el-button {
			color: #d9d9d9;
		}
	}
}

.voyage-button {
	display: flex;
	flex-direction: row-reverse;

	.ais-position {
		width: 500px;
	}
	.voyage-btn {
		padding: 5px 5px;
		margin-right: 10px;
		font-size: 12px;
		min-width: 55px;
		height: 32px;
		border-radius: 6px;
		background: #fff;
		color: #355691;
		border: 1px solid #d9d9d9;
		cursor: pointer;
	}

	.voyage-btn-size {
		padding: 0;
		min-width: 90px;
	}

	.common-btn {
		color: #484848;
		border: 1px solid #d9d9d9;
	}
}
</style>
